<template>
  <div :key="updatedAt">
    <FilterGroups 
      :gruposCampanha="grupos"
      @gruposFitlrados="handleFiltroGrupo"
    />
    <b-row v-if="grupos.length == 0">
      <b-col>
        <h4>Por favor, selecione um ou mais grupos</h4>
      </b-col>
    </b-row>
    <app-collapse v-else>
      <div v-for="(grupo, index) in gruposFiltrados" :key="grupo.id_grupo">
        <TabDistribuicaoOperador
          :vacina="vacina"
          :grupo="grupo"
          :operadores="grupo.operador ? grupo.operador : []"
          :isVisible="index == 0"
          v-if="!grupo.removido"
        />
      </div>
    </app-collapse>
  </div>
</template>

<script>
import { BBadge, BRow, BCol, } from "bootstrap-vue";
import {
  CampanhaNacionalGetGruposSelecionadoPorVacina,
  CampanhaNacionalGetState,
  CampanhaNacionalGetPrecificacaoDoseGestoTaxaVacinaGrupoEmpresaOperador
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";
import Ricon from "@/assets/images/icons/RIcon.svg";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import TabDistribuicaoOperador from "./TabDistribuicaoOperador.vue";
import FilterGroups from '@/views/custom-pages/campanha-nacional/components/FilterGroup.vue';

import { busEvent } from "@/main";

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    TabDistribuicaoOperador,
    AppCollapse,
    FilterGroups
  },

  props: {
    vacina: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    busEvent.$on("updateDistribuicaoGrupoOperadorCampanhaNacional", this.setGrupos);
  },

  methods: {
    setGrupos(id_vacina) {
      if(id_vacina !== this.vacina.id_vacina) { return; }

      let grupos = CampanhaNacionalGetGruposSelecionadoPorVacina(this.vacina);
      this.grupos = grupos != undefined ? grupos : [];
      this.updatedAt = CampanhaNacionalGetState().updatedAt;

      this.gruposFiltrados = this.grupos;
      this.filterGroupsHasPrice();
    },

    handleFiltroGrupo(gruposFiltrados) {
      this.gruposFiltrados = gruposFiltrados;
    },

    filterGroupsHasPrice() {
      this.gruposFiltrados.map(grupo => {
        grupo.operador.map(operador => {
          const precoOperador = CampanhaNacionalGetPrecificacaoDoseGestoTaxaVacinaGrupoEmpresaOperador(this.vacina.id_vacina, grupo.id_grupo, operador.id_departamento);
          if(parseFloat(precoOperador.dose_gesto_vacinal)) {
            operador.temPreco = true;
            return;
          }
          operador.temPreco = false;
        });
      });
    }
  },

  data() {
    return {
      Ricon,
      moneyMask: {
        decimal: ",",
        thousands: ".",
        precision: 2,
      },
      updatedAt: null,
      grupos: [],
      gruposFiltrados: []
    };
  },
  watch: {
    "$store.state.campanhaNacionalState.watchers.grupos": {
      immediate: false,
      handler() {
        this.setGrupos(this.vacina.id_vacina);
      },
    },
  },
};
</script>

