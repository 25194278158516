<template>
  <div class="text-left d-flex justify-content-center mt-2">
    <b-button
        v-if="stepsData.activeTabIndex > 0"
        @click="stepsData.prevTab(); prev()"
        squared
        variant="outline-custom-blue"
        class="m-0 textButton"
    >
      <div class="d-flex">
        <feather-icon icon="ArrowLeftIcon" class="mr-50"/>
        <span class="align-middle textButton">Voltar</span>
      </div>
    </b-button>
    <b-button
        v-if="!stepsData.isLastStep"
        @click="stepsData.nextTab(); next()"
        squared
        variant="outline-custom-blue"
        class="m-0 text-dark-blue container-button mt-1"
        :class="stepsData.activeTabIndex == 1 ? 'pl-1': 'pl-0'"
        :disabled="!this.company_added"
    >
      <div class="d-flex container-button">
        <span class="align-middle textButton ml-2">Avançar</span>
        <feather-icon icon="ChevronRightIcon" class="mr-50"/>
      </div>
    </b-button>
    <b-button
        @click.prevent="addCompany()"
        squared
        variant="none"
        class="m-0 text-dark-blue container-button ml-2"
        v-if="stepsData.isLastStep"
        :disabled="blockButtonAddCompany"
    >
      <div class="d-flex container-button">
        <span class="align-middle textButtonAddCompany">{{ title_button }}</span>
      </div>
    </b-button>

    <b-button
        @click.prevent="cancelAddCompany"
        squared
        variant="none"
        class="m-0 text-dark-blue"
    >
      <div class="button-cancel">
        <feather-icon
            size="15"
            icon="XIcon"
        />
      </div>
    </b-button>
  </div>
</template>

<script>
import { BButton, BCol, BRow } from 'bootstrap-vue'
import {busEvent} from "@/main";
export default {
  components: {
    BRow,
    BCol, BButton },

  props: {
    stepsData: {
      required: true
    },
    company_added: {
      required: true,
    },
    title_button: {
      required: true,
    },
    companyEditList: {
      required: false,
    },
    blockButtonAddCompany: {
      required: true
    },
  },
  methods: {
    mounted() {
      if(this.companyEditList) {
        this.next()
      }
    },
    next() {
      this.$emit('next', this.company_added)
      this.emitConditionsButton(this.stepsData)
    },
    addCompany() {
      this.$emit('operators', true)
      this.$emit('addCompany', true)
    },
    cancelAddCompany() {
      this.$emit('cancelAddCompany', true)
    },
    prev() {
      if(this.stepsData.isLastStep) {
        this.emitConditionsButton(this.stepsData)
      }
    },
    emitConditionsButton(stepsData) {
      const step = stepsData.isLastStep ? true : false
      this.$emit('prev', step)
    }
  },
}
</script>

<style scoped>
.borderButton {
  border-right: 1px solid #2772C052;
  padding-right: 0.5rem;
}

.textButton {
  margin-right: 8px;
  font-size: 1.1rem;
  border-radius: 5px !important;
}

.textButtonAddCompany {
  font-size: 1.1rem;
  border-radius: 5px !important;
}

.container-button {
  background-color: #2772c0;
  border-radius: 5px !important;
  color: white;
}

.button-cancel {
  position: absolute;
  top: -7px;
  right: -5px;
  background-color: #FFFFFF;
  text-align: center;
  font-size: 18px;
  padding: 7px;
  font-weight: lighter;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

</style>