<template>
  <div class="content-wrapper">
    <AddCompanyToGroupModal
       v-if="modal.modalAddCompany.modalShow"
      :modalShow="modal.modalAddCompany.modalShow"
      :departamentsOptions="departamentsOptions"
      :companyEditList="companyEditList"
      @companyAdded="getCompanyAdded"
      @modalClose="closeModalAddCompany"
    />

    <ConfirmModal 
      itemIdentifier="usuário"
      :modalShow="modal.modalConfirm.modalShow"
      :typeModal="modal.modalConfirm.typeModal"
      @confirmed="redirectToList"
      @canceled="closeModalConfirm"
    />

    <SucessoModal
      itemIdentifier="Grupo"
      :modalShow="modal.modalSuccess.modalShow"
      :typeModal="modal.modalSuccess.typeModal"
      :caseModal="modal.modalSuccess.caseModal"
      @confirmed="redirectToList"
      @canceled="closeModalSuccess"
    />

    <PageHeader screenName="Grupo de Empresas" :linkItems="linkItems" />

    <main class="card hidden_overflow_tabela">
      <b-overlay :show="loading" rounded="lg" opacity="0.6" class="w-100 mt-1">
        <validation-observer ref="groupForm">
          <b-form>
            <b-row class="p-2">
              <b-col lg="12" md="12" sm="12" v-if="hasCompany">
                <b-alert variant="warning" :show="hasCompany">
                  <div class="alert-body">
                    <span>Para criar o grupo, é necessário vincular empresas.</span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
            <b-row class="px-2">
              <b-col lg="12" md="12" sm="12">
                <h2>Criar novo grupo</h2>
              </b-col>
            </b-row>
            <b-row class="pt-2 px-2">
              <b-col lg="12" md="12" sm="12">
                <b-form-group 
                  label="Insira o nome do grupo *" 
                  label-for="name_group" 
                  label-class="font_size_label"
                >
                  <validation-provider 
                    #default="{ errors }" 
                    name="Nome do grupo" 
                    rules="required"
                  >
                    <b-form-input
                      id="name_group" 
                      v-model="form.name_group"
                      class="form-control"
                      maxlength=255
                      placeholder="Insira o nome do grupo"
                    />
                    <small v-if="errors[0]" class="text-danger">
                      {{errors[0]}}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            
            <b-row class="pt-2 px-2">
              <b-col lg="12" md="12" sm="12">
                <h4>Coordenação do Grupo</h4>
              </b-col>
            </b-row>

            <b-row class="pt-2 px-2">
              <b-col lg="4" md="4" sm="12">
                <b-form-group
                  label="Administrativo e Financeiro" 
                  label-for="financial_admin" 
                  label-class="font_size_label"
                >

                <v-select
                    id="financial_admin"
                    :options="departamentsOptions"
                    v-model="form.financial_admin"
                    placeholder="Selecione"
                    label="descricao"
                    item-text="id_departamento"
                    item-value="id_departamento"
                />
                </b-form-group>
              </b-col>

              <b-col lg="4" md="4" sm="12">
                <b-form-group
                  label="Relacionamento" 
                  label-for="relationship" 
                  label-class="font_size_label"
                >
                  <v-select
                      id="relationship"
                      :options="departamentsOptions"
                      v-model="form.relationship"
                      placeholder="Selecione"
                      label="descricao"
                      item-text="relationship"
                      item-value="relationship"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="4" md="4" sm="12">
                <b-form-group
                  label="Técnico" 
                  label-for="technician" 
                  label-class="font_size_label"
                >
                  <v-select
                      id="technician"
                      :options="departamentsOptions"
                      v-model="form.technician"
                      placeholder="Selecione"
                      label="descricao"
                      item-text="technician"
                      item-value="technician"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            
            <b-row class="pt-1 px-2">
              <b-col lg="4" md="4" sm="12">
                <b-button @click="addNewCompany()" variant="primary-button">
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"  
                  />
                    <span class="align-middle">Adicionar nova empresa</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      
        <hr class="mt-4">

        <CompanyList 
          @companyEdit="companyEdit"
        />
      </b-overlay>
      <b-row class="p-2">
        <b-col lg="12" md="12" sm="12">
          <section>
            <b-button id="save-group"
                class="mr-2"
                variant="custom-blue"
                @click.prevent="crateGroup"
                :disabled="hasCompany || loadingButton"
            >
                <feather-icon icon="CheckIcon" class="mr-50"/>
                <span class="align-middle">Salvar grupo</span>
                <span v-if="loadingButton" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
            </b-button>

            <b-button 
              id="cancel-create-group"
              variant="outline-danger"
              :disabled="loadingButton"
              @click.prevent="openModalConfirm"
            >
                <feather-icon icon="XIcon" class="mr-50"/>
                <span class="align-middle">Cancelar</span>
            </b-button>
        </section>
        </b-col>
      </b-row>
    </main>
  </div>
</template>

<script>
import { BAlert, BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BSpinner, BFormDatepicker, BFormTextarea, BOverlay } from 'bootstrap-vue'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate"
import VueCleave from 'vue-cleave-component'
import vSelect from "vue-select"
import CompanyList from '@/views/custom-pages/GrupoEmpresa/Components/CompanyList.vue'
import AddCompanyToGroupModal from './Components/AddCompanyToGroupModal.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import { setCompanyGroup, resetCompanyEditWhenCreatingGroup, resetCompanyEditWhenEditingGroup, resetCompanyEditIdEmpresa } from '@core/utils/store/setStore';
import { getCompanyGroup } from '@core/utils/store/getStore';
import { actions, subjects } from "@/libs/acl/rules";

export default {
  title: "Cadastar grupo empresa",
  
  components: {
    BForm, BCol, BRow, BFormGroup, BFormInput,
    BButton, BTable, CustomPagination, PageHeader,
    ValidationProvider, VueCleave, BFormDatepicker, vSelect, BFormTextarea,
    ValidationObserver, BSpinner, BOverlay, CompanyList, AddCompanyToGroupModal,
    ConfirmModal, SucessoModal,BAlert
    
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Grupo de Empresas',
          active: true
        }
      ],
      form: {
        name_group: "",
        financial_admin: "",
        relationship: "",
        technician: ""
      },
      modal: {
        modalAddCompany: {
          modalShow: false
        },
        modalConfirm: {
          modalShow: false,
          typeModal: 'danger'
        },
        modalSuccess: {
          modalShow: false,
          typeModal: 'success',
          caseModal: 'criacao'
        }
      },
      company_added : null,
      financialAdminOptions: [],
      relationshipOptions: [],
      technicianOptions: [],
      departamentsOptions: [],
      operatorsOptions: [],
      loading: false,
      loadingButton: false,
      companyEditList: false
    }
  },
  mounted() {
    this.getDepartment()
    this.verifyPermission()
  },

  computed: {
    hasCompany() {
      return getCompanyGroup().length == 0 
    }
  },

  methods: {
    getDepartment() {
      this.$http.get(this.$api.getDepartmentNational()).then(departaments => {
        this.departamentsOptions = departaments.data
        this.operatorsOptions = departaments.data.filter(departament => departament.uf)
      })
    },
    async crateGroup() {
      this.loadingButton = true
      this.$refs.groupForm.validate().then(async success => {
        if(success) {
          const parameters = {
            descricao : this.form.name_group,
            id_departamento_administrativo: this.form.financial_admin ? this.form.financial_admin.id_departamento : null,
            id_departamento_relacionamento: this.form.relationship ? this.form.relationship.id_departamento : null,
            id_departamento_tecnico: this.form.technician ? this.form.technician.id_departamento : null,
            empresa_operador: this.formatCompanyGroup()
          }

          this.loading = true
          await this.$http.post(this.$api.getAndPostGrupo(), parameters).then(data => {
            if(data.status == 201) {
              this.openModalSucess()
            }
          }).catch(({ response: { data }}) => {

            this.openModalErrorSave(data.errors)
          })
          this.loading = false
        }
        this.loadingButton = false
      })
    },

    formatCompanyGroup() {
      return getCompanyGroup().flatMap(companyGroup => {
        if (Array.isArray(companyGroup.operator)) {
          const seenIds = new Set();
          return companyGroup.operator.filter(operator => {
            if (!seenIds.has(operator.id_empresa_endereco)) {
              seenIds.add(operator.id_empresa_endereco);
              return true;
            }
            return false;
          }).map(operator => ({
            id_empresa: companyGroup.id_empresa,
            id_operador: operator.id_operador ? operator.id_operador : operator.id_departamento,
            id_unidade: operator.id_unidade,
            id_empresa_endereco: operator.id_empresa_endereco
          }));
        } else {
          return {
            id_empresa: companyGroup.id_empresa,
            id_operador: companyGroup.operator,
          };
        }
      });
    },

    addNewCompany() {
      this.modal.modalAddCompany.modalShow = true
    },

    companyEdit() {
      this.modal.modalAddCompany.modalShow = true
      this.companyEditList = true
    },

    getCompanyAdded(company) {
      setCompanyGroup(company)
    },

    closeModalConfirm() {
      this.modal.modalConfirm.modalShow = false   
    },

    openModalConfirm() {
      this.modal.modalConfirm.modalShow = true
    },

    closeModalSuccess() {
      this.modal.modalSuccess.modalShow = false
    },

    closeModalAddCompany() {
      this.modal.modalAddCompany.modalShow = false
      this.companyEditList = false
    },

    openModalSucess() {
      this.modal.modalSuccess.modalShow = true
    },

    openModalErrorSave(errorText) {
      this.$swal({
          title: 'Ocorreu um erro.',
          icon: 'warning',
          iconColor: "red",
          html: `<div>${errorText}</div>`,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: "btn btn-custom-blue"
          },
          buttonsStyling: false
      })
    },

    redirectToList() {
      this.modal.modalConfirm.modalShow = false 
      this.$router.push({ name: 'group-company-list' });
    },

    verifyPermission() {
      return this.$can(actions.INSERIR, subjects.ATUACAO_BASE_NACIONAL);
    }
  },
  created() {
    resetCompanyEditWhenCreatingGroup()
    resetCompanyEditWhenEditingGroup()
    resetCompanyEditIdEmpresa()
  }
}

</script>