<template>
  <div class="container_companies">
    <b-overlay :show="loading" rounded="lg" opacity="0.6" class="w-100">
      <div 
        class="container_company" 
        :class="{'active': companies.length === 1 && company_is_selected}"
        :id="company.id_empresa" 
        @click="handleClick" 
        v-for="company in companies" 
        :key="company.documento"
        >
          <div class="company_title">
            <h4>{{company.nome_empresa}}</h4>
          </div>
          <div class="container_content_company">
            <div>
              <span>{{ company.documento.length === 14 ? 'CNPJ' : 'CPF' }}: {{ formatDocument(company.documento) }}</span>
            </div>
            <div class="cidade">

              <span>CIDADE: {{getNomeCidade(company)}}</span>
            </div>
            <div>
              <span>UF: {{getUf(company)}}</span>
            </div>
          </div>
      </div>
  
      <div class="p-1 text-center" v-if="companies.length == 0">
        <span>Nenhuma empresas encontrada.</span>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { getCompanyGroup, getCompanyEditWhenCreatingGroup, getCompanyEditWhenEditingGroup, getCompanyEditIdEmpresa } from '@core/utils/store/getStore';
import { resetCompanyEditWhenCreatingGroup,resetCompanyEditWhenEditingGroup } from '@core/utils/store/setStore'
import { required } from '@validations'

import { BOverlay } from 'bootstrap-vue'
  export default {
    props: {
      search: {
        required: true,
      },
      isEdit: {
        required: false,
        type: Boolean,
      },
      companyEditList: {
        required: false,
        type: Boolean,
      },
      prev: {
        required: true,
        type: Boolean,
      },
      next: {
        required: true,
      },
      idGroup: {
        required: false
      }
    },

    components: {
      BOverlay
    },

    data() {
      return {  
        required,
        companies: "",
        company_is_selected: false,
        company_selected : "",
        withoutCompany : false,
        loading: false
      }
    },

    mounted() {
      this.getNationalCompanies()
      this.$emit('companySelected', null)
    },

    methods: {
      getNationalCompanies() {
        this.loading = true

        const idEdit = getCompanyEditWhenCreatingGroup() ? [] : getCompanyEditIdEmpresa()
        const parameters = {
          busca: this.search,
          id_edit : idEdit,
          id_grupo: this.idGroup
        }

        this.$http.get(this.$api.getNationalCompanies(), {params: parameters}).then(data => {
          if(getCompanyEditWhenCreatingGroup()) {
            this.companies = data.data.filter(company => company.id_empresa == getCompanyEditWhenCreatingGroup().id_empresa)
            this.company_is_selected = true
            this.company_selected = getCompanyEditWhenCreatingGroup()
            this.$emit('companySelected', [this.company_selected])
          } else if(getCompanyEditWhenEditingGroup()) {
            const companies = []
            companies.push(getCompanyEditWhenEditingGroup())
            this.companies = companies
            this.company_is_selected = true
            this.company_selected = getCompanyEditWhenEditingGroup()
            this.$emit('companySelected', [this.company_selected])
          } else {
            this.companies = data.data
          }
          
          this.loading = false
        })
        
      },

      handleClick(event) {
        if(!this.company_is_selected) {
          event.currentTarget.classList.add('active')
          this.company_selected = event.currentTarget
          this.company_is_selected = true
          const companyFiltered = this.companies.filter(company => company.id_empresa == this.company_selected.id)
          this.$emit('companySelected', companyFiltered)
        }

        if(event.currentTarget.id != this.company_selected.id) {
          if(this.company_selected instanceof HTMLElement) {
            this.company_selected.classList.remove('active')
            this.company_selected = event.currentTarget
            event.currentTarget.classList.add('active')
          }
          this.company_selected = event.currentTarget
          const companyFiltered = this.companies.filter(company => company.id_empresa == this.company_selected.id)

          if(this.next) {
            this.$emit('companySelected', companyFiltered)
          }
        }
      },

      formatDocument(document) {
        if (document.length === 14) {
          return this.$options.filters.VMask(document, '##.###.###/####-##');
        } else if (document.length === 11) {
          return this.$options.filters.VMask(document, '###.###.###-##');
        } else {
          return document;
        }
      },

      getNomeCidade(company) {
        if (company.operator && company.operator.length > 0 && company.operator[0].nome_cidade) {
          if(Array.isArray(company.operator[0].nome_cidade)) {
            return company.operator[0].nome_cidade[0]
          }

          return company.operator[0].nome_cidade
        }

        if (company.nome_cidade && company.nome_cidade.length > 0) {
          return company.nome_cidade[0]
        }

        return ' - '
      },

      getUf(company) {
        if(company.operator && company.operator.length > 0 && company.operator[0].uf) {
          if(Array.isArray(company.operator[0].uf)) {
            return company.operator[0].uf[0]
          }

          return company.operator[0].uf
        }

        if(company.uf_cidade && company.uf_cidade.length > 0) {
          return company.uf_cidade[0]
        }

        return ' - '
      }
    },

    beforeDestroy() {
      resetCompanyEditWhenCreatingGroup()
      resetCompanyEditWhenEditingGroup()
    },

    watch: {
      search(newValue) {
        this.search = newValue
        this.company_selected = ""
        this.company_is_selected = false
        this.getNationalCompanies()
      },
      prev: {
        handler(value) {
            this.prev = value
        },
        deep: true,
        immediate: true
      },
    }
  }

</script>

<style>
  .container_company {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 15px;
    border-bottom: 1px solid #D8D6DE;
    border-radius: 5px;
    cursor: pointer;
  }

  .container_company:last-child {
    border-bottom: none;
  }

  .container_content_company {
    display: flex;
    justify-content: space-between;
  }

  .container_companies {
    max-height: 30vh;
    overflow-y: scroll;
    border: 2px solid #D8D6DE;
    padding: 5px;
    border-radius: 5px;
  }  
  
  .container_companies .container_company.active {
    background-color: #f7f7f8;
  }

  .cidade {
    text-align: left;
    width: 200px;
  }

  .company_title {
    color: #6E6B7B;
    margin-bottom: 5px;
  }

  .container_companies::-webkit-scrollbar-thumb {
    background: #cad0d7;
    border-radius: 6px;
  }

  .container_companies::-webkit-scrollbar {
    width: 6px;
  }
</style>