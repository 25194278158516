<template>
  <b-row>
    <b-col lg="12" md="12" sm="12" class="mt-1">
      <h5>Operador {{ operador.departamento.sigla }}</h5>
    </b-col>

    <!-- Dose + Gesto Vacinal: -->
    <b-col lg="6" md="6" sm="12">
      <b-form-group>
        <label :for="targerForIDFocusDoseGestaoGrupo"
          >Dose + Gesto Vacinal</label
        >
        <b-input-group>
          <b-input-group-prepend>
            <div
              class="bg-custom-blue d-flex align-items-center custom-append-input rounded adjustIconInputValue"
            >
              <b-img :src="Ricon" alt="Icone que representa o real" />
            </div>
          </b-input-group-prepend>

          <b-form-input
            class="adjustInput"
            v-model="doseGestoVacinalField"
            v-money="moneyMask"
            :id="targerForIDFocusDoseGestaoGrupo"
            placeholder="00,00"
            @keyup="onlyNumber"
          />
        </b-input-group>
      </b-form-group>
    </b-col>

    <!-- Taxa de Gestão: -->
    <b-col lg="6" md="6" sm="12">
      <b-form-group>
        <label :for="targerForIDFocusDoseTaxaGrupo">Taxa de Gestão:</label>
        <b-input-group>
          <b-input-group-prepend>
            <div
              class="bg-custom-blue d-flex align-items-center custom-append-input rounded adjustIconInputValue"
            >
              <b-img :src="Ricon" alt="Icone que representa o real" />
            </div>
          </b-input-group-prepend>
          <b-form-input
            class="adjustInput"
            v-model="taxaDeGestaoField"
            v-money="moneyMask"
            :id="targerForIDFocusDoseTaxaGrupo"
            placeholder="00,00"
            @keyup="onlyNumber"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BCollapse,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BForm,
} from "bootstrap-vue";
import Ricon from "@/assets/images/icons/RIcon.svg";
import helpers from "@/helpers";
import { 
  CampanhaNacionalSetPrecificacaoDoseGestoTaxaVacinaGrupoOperadorValores, 
  CampanhaNacionalGetPrecificacaoDoseGestoTaxaVacinaGrupoEmpresaOperador 
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";

import { busEvent } from "@/main";

export default {
  components: {
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCol,
    BCollapse,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    BForm,
  },
  props: {
    moneyMask: {
      type: Object,
      required: true,
    },
    vacina: {
      type: Object,
      required: true,
    },
    grupo: {
      type: Object,
      required: true,
    },
    operador: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Ricon,
      renderizado: false,
      taxaDeGestaoField: 0,
      doseGestoVacinalField: 0,
      targerForIDFocusDoseTaxaGrupo: helpers.geraNumeroAleatorio().toString(),
      targerForIDFocusDoseGestaoGrupo: helpers.geraNumeroAleatorio().toString(),
    };
  },
  mounted() {
    this.handleEditCampaignInfo();
  },
  methods: {
    handleEditCampaignInfo() {
      if (this.vacina.grupos) {
        const grupoPreco = this.vacina.grupos.find(
          (grupo) => grupo.id_grupo == this.grupo.id_grupo
        );
        if (grupoPreco) {
          const grupoOperadorPreco = grupoPreco.campanha_grupo_preco.find(
            (grupoOperador) =>
              grupoOperador.id_grupo_operador == this.operador.id_grupo_operador
          );
          if (grupoOperadorPreco) {
            const valores = CampanhaNacionalGetPrecificacaoDoseGestoTaxaVacinaGrupoEmpresaOperador(
              this.vacina.id_vacina,
              this.grupo.id_grupo,
              this.operador.id_departamento
            );

            const valorTaxa = valores.taxa_de_gestao.replace(".", ",");
            const valorDose = valores.dose_gesto_vacinal.replace(".", ",");

            this.setValores("taxa_de_gestao", valorTaxa);
            this.taxaDeGestaoField = valorTaxa;

            this.setValores("dose_gesto_vacinal", valorDose);
            this.doseGestoVacinalField = valorDose;

            this.$emit(
              "update",
              "grupoOperador-" + grupoOperadorPreco.id_grupo_operador
            );
          }
        }
      }
      this.renderizado = true;
    },
    setValores(campoAlteracao, valor) {
      CampanhaNacionalSetPrecificacaoDoseGestoTaxaVacinaGrupoOperadorValores(
        this.vacina.id_vacina,
        this.grupo.id_grupo,
        this.operador.id_departamento,
        campoAlteracao,
        valor
      );
    },
    onlyNumber(event) {
      busEvent.$emit('updateDistribuicaoGrupoOperadorCampanhaNacional', this.vacina.id_vacina);
      if (!helpers.keyIsNumber(event)) {
        event.preventDefault();
      }
    },
  },
  watch: {
    doseGestoVacinalField: {
      immediate: false,
      handler() {
        if (this.renderizado) {
          this.setValores("dose_gesto_vacinal", this.doseGestoVacinalField);
        }
      },
    },
    taxaDeGestaoField: {
      immediate: false,
      handler() {
        if (this.renderizado) {
          this.setValores("taxa_de_gestao", this.taxaDeGestaoField);
        }
      },
    },
  },
};
</script>

