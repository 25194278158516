<template>
  <main>
    <ModalAlteracaoDepartamento
        :modalShow="modalAlteracaoDepartamento.showModal"
        @confirmed="confirmarAlteracaoDepartamento"
        @canceled="cancelarAlteracaoDepartamento"
    />
    <b-overlay :show="loading" rounded="lg" opacity="0.6" class="w-100">
      <b-row class="mt-2">
        <b-col class="d-flex" lg="12" md="12" sm="12">
          <b-col lg="3" class="container-company-info">
            <div lg="1" class="container-icon-by-company">
              <div>
                <feather-icon icon="MapPinIcon" class="icon-by-company" size="16" />
              </div>
            </div>
            <div>
              <h4>{{address.descricao_label}}</h4>
              <div>
                {{address.logradouro}} nº {{address.numero}} {{address.nome}} - {{address.uf}}

              </div>
              <div>CEP: {{address.cep | VMask('#####-###')}}</div>
            </div>
          </b-col>

          <b-col lg="3" md="4" sm="12">
            <b-form-group
                label="DR Operador"
                label-for="operator"
                label-class="font_size_label"
            >
              <b-tooltip
                  :target="'departament-' + chave"
                  placement="top"
                  triggers="hover"
                  v-if="address.bloqueado_departamento || address.bloqueado"
              >
                Não é possível alterar o DR Operador em um endereço de uma empresa cujo Grupo de Empresas esteja em uma
                campanha Ativa ou tenha adesões com doses distribuídas maior que zero
              </b-tooltip>
              <v-select
                  :id="'departament-' + chave"
                  :options="departamentsOptions"
                  v-model="departamentSelected"
                  placeholder="Selecione"
                  label="descricao"
                  item-text="id_operador"
                  item-value="id_operador"
                  class="custom-v-select"
                  @input="handleDepartamentChange"
                  :disabled="address.bloqueado_departamento || address.bloqueado"
                  :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col lg="3" md="4" sm="12">
            <b-form-group
                label="Região"
                label-for="region"
                label-class="font_size_label"
            >
              <b-tooltip
                  v-if="address.bloqueado"
                  :target="'region-' + chave"
                  placement="top"
                  triggers="hover"
              >
                Não é possível alterar Região ou Unidade Operacional em um endereço de uma empresa que possua vínculo com a
                adesão de uma campanha em andamento e houve doses aderidas
              </b-tooltip>
              <v-select
                  :id="'region-' + chave"
                  :options="regionOptions"
                  v-model="regionSelected"
                  placeholder="Selecione"
                  label="descricao"
                  item-text="id_regiao"
                  item-value="id_regiao"
                  class="custom-v-select"
                  @input="handleRegionChange"
                  :disabled="address.bloqueado"
                  :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col lg="3" md="4" sm="12">
            <b-form-group
                label="Unidade Operacional"
                label-for="unit"
                label-class="font_size_label"
            >
              <b-tooltip
                  :target="'unit-' + chave"
                  placement="top"
                  triggers="hover"
                  v-if="address.bloqueado"
              >
                Não é possível alterar Região ou Unidade Operacional em um endereço de uma empresa que possua vínculo com a
                adesão de uma campanha em andamento e houve doses aderidas
              </b-tooltip>
              <v-select
                  :id="'unit-' + chave"
                  :options="operatorOptions"
                  v-model="operatorSelected"
                  placeholder="Selecione"
                  label="descricao_unidade"
                  item-text="id_unidade"
                  item-value="id_unidade"
                  class="custom-v-select"
                  @change="handleUnitSelect"
                  :disabled="address.bloqueado"
                  :clearable="false"
              />
            </b-form-group>
          </b-col>
        </b-col>
      </b-row>
    <div v-if="!isLast" class="line-break"></div>
    </b-overlay>
  </main>
</template>

<script>
import {BAlert, BCol, BFormGroup, BOverlay, BRow, BTooltip, VBTooltip} from 'bootstrap-vue'
import vSelect from "vue-select"
import ModalAlteracaoDepartamento from "@/views/custom-pages/empresa/Modal/ModalAlteracaoDepartamento.vue";
import IncompleteAddress from "@/views/custom-pages/GrupoEmpresa/Components/IncompleteAddress.vue";
export default {
  components: {
    BOverlay,
    IncompleteAddress,
    BFormGroup,
    BAlert,
    BRow,
    BCol,
    vSelect,
    BTooltip,
    ModalAlteracaoDepartamento
  },
  directives: {
    'b-tooltip': BTooltip
  },
  props: {
    company_added: {
      required: true,
    },
    address: {
      required: true,
    },
    departamentsOptions: {
      required: true,
    },
    isLast: {
      required: true,
      default: false
    },
    emitEventOperators: {
      required: true,
      type: Boolean
    },
    operators: {
      required: true,
    },
    chave: {
      required: false
    }
  },
  data() {
    return {
      form: [],
      regionOptions: [],
      operatorOptions: [],
      DepartamentOptions: [],
      regionSelected: null,
      operatorSelected: null,
      departamentSelected: null,
      modalAlteracaoDepartamento: {
        showModal: false,
      },
      initiallySelectedDepartment: null,
      initiallySelectedRegion: null,
      initiallySelectedUnit: null,
      initiallyDepartamentFromUF: null,
      loading: true,
    }
  },
  async mounted() {
    this.departamentSelected = null
    this.regionSelected = null
    this.operatorSelected = null
    if(this.operators.length == 0) {
      await this.updateRegionDepartamentUnit()
    }
    await this.setAddressOperator()
  },
  methods: {
    async getRegions(idDepartament) {
      const params = {
        ativo: true,
        id_departamento: idDepartament,
        todos: true,
      }

      const regions = await this.$http.get(this.$api.regiao(), { params: params })
      let regionOptions
      if(regions.data.length <= 2) {
        regionOptions = [regions.data[0]]
        this.regionSelected = regions.data[0]
        await this.getUnits(this.regionSelected.id_regiao)
        if(!this.initiallySelectedRegion) {
          this.initiallySelectedRegion = regions.data[0]
        }
      } else {
        regionOptions = regions.data
      }
      this.regionOptions = regionOptions
    },
    async getUnits(idRegion) {
      const parameters = {
        ativo : true,
        todos: true,
        id_regiao: idRegion
      }
      const units = await this.$http.get(this.$api.unidade(), {params : parameters})
      this.operatorOptions = units.data

      return units.data
    },
    async setUnit(idRegion = null) {
      this.operatorSelected = null
      this.initiallySelectedUnit = null
      if(idRegion) {
        await this.getUnits(idRegion)
      } else {
        await this.getUnits(this.address.id_regiao)
        const unitSelected = this.operatorOptions.find(operator => operator.id_unidade === this.address.id_unidade)
        this.operatorSelected = unitSelected
        this.initiallySelectedUnit = unitSelected
      }
    },
    async setRegion(idRegion = null, idDepartament = null) {
      if(idRegion) {
        await this.setUnit(idRegion)
      } else {
        await this.getRegions(idDepartament ? idDepartament : this.address.id_departamento)
        let regionSelected
        if(this.regionOptions.length > 2) {
          let idRegionFind = this.address.id_regiao

          if (this.operators.length > 0) {
            idRegionFind = this.regionSelected ? this.regionSelected.id_regiao : this.address.id_regiao
          }
          regionSelected = this.regionOptions.find(region => region.id_regiao === idRegionFind)
          this.regionSelected = regionSelected
          this.initiallySelectedRegion = regionSelected
        }
      }
    },
    setDepartament() {
      const departamentSelected = this.departamentsOptions.find(item => item.id_departamento === this.address.id_departamento)
      const departamentFromUF = this.departamentsOptions.find(item => item.uf === this.address.uf)

      this.departamentSelected = departamentSelected
      this.initiallySelectedDepartment = departamentSelected
      this.initiallyDepartamentFromUF = departamentFromUF
    },
    handleRegionChange(region) {
      if(region) {
        this.setRegion(region.id_regiao)
      }
    },
    handleDepartamentChange(departament) {
      if(departament) {
        this.setRegion(null, departament.id_departamento)
      }
      this.operatorSelected = null
    },
    handleUnitSelect(unit) {
      this.operatorSelected = unit
    },
    async updateRegionDepartamentUnit() {
      await this.setRegion()
      this.setDepartament()
      const idRegion = this.departamentSelected ? this.departamentSelected.id_regiao : null
      await this.setUnit(idRegion)
    },
    closeModalAlteracaoDepartamento() {
      this.modalAlteracaoDepartamento.showModal = false
    },
    confirmarAlteracaoDepartamento() {
      this.closeModalAlteracaoDepartamento()
    },
    cancelarAlteracaoDepartamento() {
      this.closeModalAlteracaoDepartamento()
      this.departamentSelected = this.initiallySelectedDepartment
      this.regionSelected = this.initiallySelectedRegion
      this.operatorSelected = this.initiallySelectedUnit
    },
    async setAddressOperator() {
      if(this.operators.length > 0) {
        this.operators.forEach(operator => {
          if(operator.id_empresa_endereco == this.address.id_empresa_endereco) {
            this.departamentSelected = operator.departamento
            this.operatorSelected = operator.unidade
            this.regionSelected = operator.regiao
            this.getRegions(this.departamentSelected.id_departamento)
            this.setRegion(null,this.departamentSelected.id_departamento)
          } else {
            this.updateRegionDepartamentUnit()
          }
        })
      }
    },
  },
  watch: {
    async address() {
      this.loading = true
      await this.updateRegionDepartamentUnit()
    },
    emitEventOperators() {
      this.$emit('getOperators',{
        id_departamento: this.departamentSelected.id_departamento,
        descricao: this.departamentSelected.descricao,
        uf: this.address.uf,
        nome_cidade: this.address.nome,
        id_empresa_endereco: this.address.id_empresa_endereco,
        id_empresa: this.address.id_empresa,
        id_unidade: this.operatorSelected ? this.operatorSelected.id_unidade : null,
        unidade: this.operatorSelected,
        departamento: this.departamentSelected,
        regiao: this.regionSelected,
      })
    },
    async departamentSelected() {
      if(this.operators.length == 0 && this.departamentSelected) {
          const departamentIsDifferentFromInitiallyDepartment = this.departamentSelected.id_departamento != this.initiallySelectedDepartment.id_departamento;
          const departamentIsDifferentFromInitiallyDepartamentFromUF = this.departamentSelected.id_departamento != this.initiallyDepartamentFromUF.id_departamento;
          if(departamentIsDifferentFromInitiallyDepartment && departamentIsDifferentFromInitiallyDepartamentFromUF) {
            this.modalAlteracaoDepartamento.showModal = true
          }
      }
    },
    operatorSelected() {
      this.loading = false
    }
  },
}
</script>

<style>
.icon-by-company {
  color: #337ac3;
}

.container-company-info {
  display: flex;
}

.container-icon-by-company {
  width: 32px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  margin-right: 15px;
}

.container-icon-by-company > div {
  background-color: #2772C01F;
  padding: 10px;
  border-radius: 100px;
}

.line-break {
  height: 1px;
  background-color: #EBE9F1;
  margin-top: 22px;
}

</style>