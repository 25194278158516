<template>

  <b-modal
      id="modal-add-company"
    no-close-on-backdrop 
    no-close-on-esc 
    hide-header 
    hide-footer 
    centered 
    v-model="modalShow"
    size="xl"
    body-class="modal-body-content"
    :style="{ padding: '0px !important' }"
  >
    <IncompleteAddress
        :modalShow="modalEnderecoIncompleto.showModal"
        @confirmed="closeModalIncompleteAddress"
    />
      <form-wizard
          color="#2772C0"
          :title="null"
          :subtitle="null"
          :next="false"
          :prev="false"
          ref="wizard"
      >
        <tab-content
            title="Selecionar Empresa"
            icon="feather icon-box"
            :beforeChange="() => true"
        >
          <validation-observer ref="companyForm">
            <b-row>
              <b-col lg="12" md="12" sm="12" class="mb-2">
                <h2>{{ titleModal}}</h2>
              </b-col>
            </b-row>
            <b-row v-if="!showSearch">
              <b-col lg="12" md="12" sm="12">
                <b-form-group
                    label="CNPJ | CPF | Nome Fantasia"
                    label-for="name_group"
                    label-class="font_size_label"
                    class="search-container"
                >
                  <b-form-input
                      id="company_identifier"
                      v-model="company_identifier"
                      class="form-control"
                      placeholder="000.000.000-00"
                  />
                  <div class="search-input-icon">
                    <feather-icon
                        icon="SearchIcon"
                        size="22"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="12" md="12" sm="12">
                <CompanyListToAddGroup
                    @companySelected="getCompanySelected"
                    :search="company_identifier"
                    :isEdit="isEdit"
                    :companyEditList="companyEditList"
                    :prev="prev"
                    :next="next"
                    :idGroup="idGroup"
                />
              </b-col>
            </b-row>

          </validation-observer>
        </tab-content>

        <tab-content
            title="Validar DR Operador"
            icon="feather icon-check-square"
            :beforeChange="() => true"
        >
          <div
            v-if="company_added"
          >
            <h2>Validar DR Operador por Endereço </h2>
            <b-row class="mt-2">
              <b-col lg="12" md="12" sm="12">
                <b-alert variant="warning" :show="true">
                  <div class="alert-body alert-company d-flex align-items-center">
                    <feather-icon
                        icon="AlertTriangleIcon"
                        size="22"
                        class="mr-1"
                    />
                    <span>
                      A alteração dos dados do <strong>DR Operador</strong> afetará as <strong>adesões</strong> realizadas
                      para esta empresa/endereço, bem como os processos de <strong>vacinação</strong> e a <strong>geração de relatórios</strong>
                      associados a essa empresa
                    </span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col lg="12" md="12" sm="12">
                <b-alert variant="info" :show="true">
                  <div class="alert-body info-company" >
                  <span>
                    <strong>Empresa:</strong>
                    {{this.company_added ? this.company_added[0].nome_empresa : ''}}
                  </span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
            <b-row class="mt-1" v-if="addressCompany">
              <b-col lg="12" md="12" sm="12">
                <b-alert variant="danger" :show="company_added ? addressCompany.length == 0 : false">
                  <div class="alert-body alert-company">
                <span>
                  <feather-icon
                      icon="AlertCircleIcon"
                      size="22"
                  />
                  Essa empresa não possui endereços. É necessário cadastrar pelo menos um endereço para habilitar um DR
                  operador e inseri-la em um grupo.
                </span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>

              <ValidateDrOperator
                  v-if="addressCompany.length > 0"
                  v-for="(address,index) in addressCompany"
                  :key="index"
                  :chave="index"
                  :address="address"
                  :company_added="company_added"
                  :departamentsOptions="departamentsOptions"
                  :isLast="index === addressCompany.length - 1"
                  :emitEventOperators="emitEventOperators"
                  :operators="operatorArr"
                  @getOperators="getOperators"
                  @finishLoading="finishLoadingValidateDrOperator"
                  @initLoading="initLoadingDrValidateDrOperator"
                  @addressIncomplete="addressIncomplete"
              />

          </div>
        </tab-content>

        <template slot="footer" slot-scope="props">
          <ButtonsAddCompany
              :stepsData="props"
              :company_added="company_added"
              :title_button="titleButton"
              :companyEditList="companyEditList"
              :blockButtonAddCompany="blockButtonAddCompany"
              @next="nextValidateDrOperator();updateNext()"
              @cancelAddCompany="openModalConfirm"
              @operators="operators"
              @prev="prevButton"
          />
        </template>
      </form-wizard>

  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BTab,
  BTabs,
  BImg,
  BAlert,
  BOverlay
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from '@validations'
import ButtonsAddCompany from '@/views/custom-pages/GrupoEmpresa/Components/ButtonsAddCompany.vue'
import ValidateDrOperator from "@/views/custom-pages/GrupoEmpresa/Components/ValidateDrOperator.vue"
import vSelect from "vue-select"
import CompanyListToAddGroup from './CompanyListToAddGroup.vue';
import {
  getCompanyEditWhenCreatingGroup,
  getCompanyEditWhenEditingGroup,
  getIdCompanyGroup
} from '@core/utils/store/getStore'
import {setCompanyEditWhenCreatingGroup, setCompanyEditWhenEditingGroup, resetCompanyEditWhenCreatingGroup} from '@core/utils/store/setStore'
import {getCompanyWithOperators} from "@core/utils/store/getStore";
import { FormWizard, TabContent } from "vue-form-wizard";
import GroupPricingDoseDistribuitionVacina
  from '@/views/custom-pages/campanha-nacional/Edit/FormGroups/GroupPricingDoseDistribuitionVacina.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import IncompleteAddress from "@/views/custom-pages/GrupoEmpresa/Components/IncompleteAddress.vue";

export default {

  components: {
    IncompleteAddress,
    BOverlay,
    BImg, GroupPricingDoseDistribuitionVacina, BModal, BButton, BForm, BCol, BRow, BFormGroup, BFormInput,
    CompanyListToAddGroup, vSelect, ValidationProvider, ValidationObserver, BTab, BTabs, FormWizard, TabContent,
    ButtonsAddCompany, BAlert, ValidateDrOperator
  },

  props: {
    modalShow: {
      required: true,
      type: Boolean,
    },
    departamentsOptions: {
      required: true,
      type: Array,
    },
    isEdit: {
      required: false,
      type: Boolean,
    },
    companyEditList: {
      required: false,
      type: Boolean,
    },
    idGroup: {
      required: false
    }
  },

  data() {
    return {
      required,
      companies: [],
      company_identifier: "",
      operator: "",
      company_added: null,
      addressCompany: null,
      operatorArr: [],
      emitEventOperators: false,
      blockButtonAddCompany: false,
      prev: false,
      next: null,
      operatorIncomplete: false,
      loading: true,
      modalEnderecoIncompleto: {
        showModal: false
      },
      isAddressIncomplete: [],
    }
  },

  computed: {
    titleModal() {
      return getCompanyEditWhenCreatingGroup() || getCompanyEditWhenEditingGroup() ? 'Editar Empresa' : 'Adicionar Empresa'
    },

    showSearch() {
      return getCompanyEditWhenCreatingGroup() || getCompanyEditWhenEditingGroup()
    },

    titleButton() {
      return getCompanyEditWhenCreatingGroup() || getCompanyEditWhenEditingGroup() ? 'Salvar Empresa' : 'Adicionar Empresa'
    },
    showModals() {
      return getCompanyEditWhenCreatingGroup() || getCompanyEditWhenEditingGroup()
    }
  },
  mounted() {
    this.operatorArr = []
  },
  methods: {
    operators() {
      this.emitEventOperators = !this.emitEventOperators
    },
    getOperators(operator) {
      const existingIndex = this.operatorArr.findIndex(operatorItem => operatorItem.id_empresa_endereco === operator.id_empresa_endereco && operatorItem.id_empresa == operator.id_empresa)
      if(existingIndex != -1) {
        const existingItem = this.operatorArr[existingIndex]
        if(existingItem.id_unidade) {
          this.addCompany()
        } else {
          this.operatorArr.splice(existingIndex, 1)
        }
      }
      this.operatorArr.push(operator)
    },
    async addCompany() {
      const operatorNull = this.operatorArr.filter(operator => operator.id_unidade == null)

      if(operatorNull.length > 0) {
        this.modalEnderecoIncompleto.showModal = true
        return
      }
      const companyAdded = this.defineCompanyHandle()

      if(this.showModals && !this.operatorIncomplete){
        this.openModalSuccess();
      }
      this.$emit('companyAdded', companyAdded)
      this.clearForm()

      this.$emit('modalClose', true)
    },
    updateNext() {
      this.next = true
    },
    async nextValidateDrOperator() {
      this.blockButtonAddCompany = true
      await this.getEmpresaEndereco(this.company_added[0].id_empresa)
    },
    async getEmpresaEndereco(idCompany) {
      const address = await this.$http.get(this.$api.getEmpresaEnderecoByIdEmpresa(idCompany));
      this.addressCompany = address.data;
      if(this.addressCompany.length == 0) {
        this.blockButtonAddCompany = true
      } else {
        this.blockButtonAddCompany = false
      }
    },
    defineCompanyHandle() {
      let companyAdded
      if(getCompanyEditWhenCreatingGroup()) {
        companyAdded = this.formatObjCompany(getCompanyEditWhenCreatingGroup(), this.operatorArr)
        this.companyEditedWhenCreatingGroup(companyAdded)
      } else if(getCompanyEditWhenEditingGroup()) {
        companyAdded = this.formatObjCompany(getCompanyEditWhenEditingGroup(), this.operatorArr)
        this.companyEditedWhenEditingGroup(companyAdded)
      } else {
        companyAdded = this.formatObjCompany(this.company_added[0], this.operatorArr)
      }

      if(companyAdded.operator.length == 0) {
        this.operatorIncomplete = true
      }

      return companyAdded
    },

    finishLoadingValidateDrOperator() {
      this.loading = false
    },

    initLoadingDrValidateDrOperator() {
      this.loading = true
    },

    addressIncomplete(operator) {
      if(!operator.id_unidade) {
        this.modalEnderecoIncompleto.showModal = true
        return true
      }

    },

    formatObjCompany(company, operator) {
      return {
        ...company,
        operator,
      }
    },

    companyEditedWhenCreatingGroup(companyAdded) {
      if(getCompanyEditWhenCreatingGroup()) {   
        setCompanyEditWhenCreatingGroup(companyAdded)
      }
    },

    companyEditedWhenEditingGroup(companyAdded) {
      if(getCompanyEditWhenEditingGroup()) {
        setCompanyEditWhenEditingGroup(companyAdded)
      }
    },

    clearForm() {
      this.operator = ""
      this.company_identifier = ""
      resetCompanyEditWhenCreatingGroup()
    },

    cancelModal(){
      this.$emit('modalClose', true)
      this.clearForm()
    },

    getCompanySelected(company){
      if(company) {
        this.company_added = company
      }
    },
    prevButton(value) {
      this.prev = value
    },
    closeModalIncompleteAddress() {
      this.modalEnderecoIncompleto.showModal = false
    },
    openModalSuccess() {
      this.$swal({
          title: '',
          icon: 'success',
          iconColor: "#28c76f",
          html: '<div>Alteração salva com sucesso.</div>',
          confirmButtonText: 'Ok!',
          customClass: {
            confirmButton: "btn btn-outline-success"
          },
          buttonsStyling: false
      })
    },
    openModalConfirm() {
      if(!this.showModals){
        this.cancelModal();
      }else{
        this.$swal({
            title: 'Tem certeza de que deseja fechar sem salvar as alterações?',
            icon: 'warning',
            iconColor: "orange",
            html: '<div>As mudanças não salvas serão perdidas.</div>',
            confirmButtonText: 'Continuar editando',
            showCancelButton: true,
            cancelButtonText: 'Sim, cancelar',
            customClass: {
              confirmButton: "btn btn-custom-blue mr-2",
              cancelButton: 'btn btn-outline-warning'
            },
            buttonsStyling: false
        }).then((result) => {
          if (result.isDismissed) {
            this.cancelModal();
          }
      })
      }
  }

  },

  beforeDestroy() {
    this.clearForm()
    resetCompanyEditWhenCreatingGroup()
    this.operatorArr = []
  },

  watch: {
    '$store.state.groupState.companyEditWhenCreatingGroup': {
      handler(company) {
        if(company != null) {
          this.operator = {
            id_operador : company.id_operador,
            descricao: company.descricao_operador,
            sigla: company.sigla_operador,
            nome_cidade: company.nome_cidade,
            id_unidade: company.id_unidade,
            id_empresa_endereco: company.id_empresa_endereco,
            id_empresa: company.id_empresa,
          }
        }
      },
      deep: true

    },
    '$store.state.groupState.companyEditWhenEditingGroup': {
      handler(company) {
        if(company != null) {
          this.operator = {
            id_operador : company.id_operador,
            descricao: company.descricao_operador,
            sigla: company.sigla_operador,
            nome_cidade: company.nome_cidade,
            id_unidade: company.id_unidade,
            id_empresa_endereco: company.id_empresa_endereco,
            id_empresa: company.id_empresa,
          }
        }
      },
      deep: true
    },
    modalShow(newVal) {
      if(newVal) {
        this.company_identifier = "";
        this.company_added = null;
        this.form = [];
        this.regionOptions = [];
        this.addressCompany = null;
        this.departamentSelected = null;
        this.clearForm()
      }
    },
    company_added: {
      handler() {
        if (this.company_added && this.companyEditList) {
          this.nextValidateDrOperator();
          if(this.$refs.wizard) {
            this.$refs.wizard.nextTab()
          }
        }
      },
      deep: true
    },
    operatorArr(operator) {
      if(operator.length > 0) {
        this.$nextTick(() => {
          this.isAddressIncomplete = []
          const operatorWithoutUnidade = this.operatorArr.filter(item => !item.id_unidade)
          if(operatorWithoutUnidade.length > 0) {
            this.modalEnderecoIncompleto.showModal = true
            this.isAddressIncomplete = operatorWithoutUnidade
          } else {
            this.addCompany()
          }
        })
      }


    },

  },

  destroyed() {
    this.operatorArr = []
  }
}
</script>

<style>
#modal-add-company .modal-body {
  padding: 0px !important;
}

  .modal-add-company {
    padding: 0 !important;
  }

  .buttons {
    text-align: center;
    margin-top: 15px;
  }

  .search-container {
    position: relative;
  }

  .search-input-icon {
    position: absolute;
    top: 50%;
    right: 10px; 
    cursor: pointer;
    color: #b6b2b5;
    font-weight: bold;
  }

  .container-icon {
    background-color: #2772c0;
    border-radius: 5px;
    margin-right: 5px;
    padding: 5px;
  }

  .container-icon img {
    filter: invert(50%) sepia(100%) saturate(100%) hue-rotate(0deg) brightness(100%) contrast(100%);
  }


  .alert-company > span > strong {
    color: #FF9110;
    font-weight: 900;
  }

  .alert-company > span {
    font-weight: 500;
    font-size: 16px;
  }

  .info-company {
    background-color: #e5eef7;
  }

  .info-company > span > strong{
    font-weight: 900;
    color: #2772C0;
  }

  .info-company > span {
    color: #2772C0;
    font-size: 16px;
    font-weight: 500;
  }

  custom-width {
    width: 50px;
    flex-shrink: 0;
  }

  .container-company-info > h4{
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #6E6B7B;
  }

  .container-company-info div {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #6E6B7B;
  }

</style>