import $store from '@/store'
import $http from '@/libs/api';
import $api from '@/router/rotasAPI'

const preencheuTodosOsValoresDoGrupo = (precificacao, idVacina, idGrupo) => {
    let grupo = precificacao[idVacina][idGrupo],
        precificouEmpresa = grupo.valor_empresa > 0;

    let todosMaioresQueZero = true;
    for (const chave in grupo.operador) {
        const departamento = grupo.operador[chave];
        if (departamento.dose_gesto_vacinal <= 0) {
            todosMaioresQueZero = false;
           
        }
    }
    return precificouEmpresa && todosMaioresQueZero;
}

const preencheuAoMenosUmDosValoresGrupo = (precificacao, idVacina, idGrupo) => {
    let grupo = precificacao[idVacina][idGrupo],
        precificouEmpresa = grupo.valor_empresa > 0;

        let algumValorEstaMaiorQueZero = false;
        for (const chave in grupo.operador) {
            const departamento = grupo.operador[chave];
            if (parseFloat(departamento.dose_gesto_vacinal) > 0) {
                algumValorEstaMaiorQueZero = true;
                break;
            }
        }

    return precificouEmpresa && algumValorEstaMaiorQueZero;
}

const headers = () => {
    return {
        headers: {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        },
    };
}

// getters vuex
export function CampanhaNacionalGetState() {
    return $store.getters["campanhaNacionalState/state"];
}
export function CampanhaNacionalGetFormDesabilitarEnvio() {
    return $store.getters["campanhaNacionalState/getFormDesabilitarEnvio"];
}
export function CampanhaNacionalGetFormDoencas() {
    return $store.getters["campanhaNacionalState/getFormDoencas"];
}
export function CampanhaNacionalGetFormVacinas() {
    return $store.getters["campanhaNacionalState/getFormVacinas"];
}
export function CampanhaNacionalGetFormGrupos() {
    return $store.getters["campanhaNacionalState/getFormGrupos"];
}
export function CampanhaNacionalGetStatePayload() {
    return $store.getters["campanhaNacionalState/payload"];
}
export function CampanhaNacionalGetDataInicio() {
    return $store.getters["campanhaNacionalState/getDataInicio"];
}
export function CampanhaNacionalGetDataTermino() {
    return $store.getters["campanhaNacionalState/getDataTermino"];
}
export function CampanhaNacionalGetDocumentoVacinaGrupo(idVacina, idGrupo) {
    const documentos = $store.getters["campanhaNacionalState/getDocumentos"],
        documentosVacinaGrupo = documentos[idVacina][idGrupo];

    return documentosVacinaGrupo != undefined ? documentosVacinaGrupo : [];
}
export function CampanhaNacionalGetPrecificacaoStatus(getStatus = false) {
    const precificacao = $store.getters["campanhaNacionalState/getPrecificacao"];
    let status = {};

    Object.keys(precificacao).forEach((idVacina) => {
        status[idVacina] = {};
        if (precificacao[idVacina]) {
            Object.keys(precificacao[idVacina]).forEach((idGrupo) => {
                if(getStatus) {
                    status[idVacina][idGrupo] = preencheuAoMenosUmDosValoresGrupo(precificacao, idVacina, idGrupo);
                } else {
                    status[idVacina][idGrupo] = preencheuTodosOsValoresDoGrupo(precificacao, idVacina, idGrupo);
                }
            });
        }
    });

    return status;
}
export function CampanhaNacionalGetPrecificacaoStatusCompleto(idVacina, idGrupo = null) {
    let precificacao = CampanhaNacionalGetPrecificacaoStatus(true),
        precificacaoPorVacina = precificacao[idVacina];

    return idGrupo == null ?
        !Object.values(precificacaoPorVacina).includes(false) :
        precificacaoPorVacina[idGrupo];
}

export function CampanhaNacionalGetDescricao() {
    return $store.getters["campanhaNacionalState/getDescricao"];
}
export function CampanhaNacionalGetDoencaSelecionada() {
    return $store.getters["campanhaNacionalState/getDoencaSelecionada"];
}
export function CampanhaNacionalGetVacinasSelecionada() {
    return $store.getters["campanhaNacionalState/getVacinasSelecionada"];
}
export function CampanhaNacionalGetQtdAdesoes() {
    return $store.getters["campanhaNacionalState/getQtdAdesoes"];
}

export function CampanhaNacionalGetGruposSelecionadoPorVacina(vacina) {
    let gruposVacina = $store.getters["campanhaNacionalState/getGruposSelecionado"][vacina.id_vacina];
    return gruposVacina == undefined ? [] : Object.values(gruposVacina);
}
export function CampanhaNacionalGetGruposSelecionado() {
    return $store.getters["campanhaNacionalState/getGruposSelecionado"];
}
export function CampanhaNacionalGetPreencheuDadosMinimos() {
    return $store.getters["campanhaNacionalState/getPreencheuDadosMinimos"];
}
export function CampanhaNacionalGetPrecificacaoValorEmpresaVacinaGrupoEmpresa(idVacina, idGrupo) {
    const precificacao = $store.getters["campanhaNacionalState/getPrecificacao"];
    let valorEmpresa = 0;
    if (precificacao[idVacina] && precificacao[idVacina][idGrupo]) {
        valorEmpresa = precificacao[idVacina][idGrupo].valor_empresa
    }
    return valorEmpresa
}

export function CampanhaNacionalGetPrecificacaoDoseGestoTaxaVacinaGrupoEmpresaOperador(idVacina, idGrupo, idDepartamento) {
    const precificacao = $store.getters["campanhaNacionalState/getPrecificacao"];
    let valorDoseGestoTaxa = {
        id_departamento: idDepartamento,
        dose_gesto_vacinal: 0,
        taxa_de_gestao: 0,
    };

    if (precificacao[idVacina] && precificacao[idVacina][idGrupo] && precificacao[idVacina][idGrupo].operador[idDepartamento]) {
        valorDoseGestoTaxa = precificacao[idVacina][idGrupo].operador[idDepartamento]
    }

    return valorDoseGestoTaxa
}

export function CampanhaNacionalGetQtdDistribuicaoVacinaGrupoOperador(idVacina, idGrupo, idDepartamento) {
    const distribuicao = $store.getters["campanhaNacionalState/getDistribuicao"];
    let qtdDose = { qtd_dose: 0 };

    if (distribuicao[idVacina] && distribuicao[idVacina][idGrupo] && distribuicao[idVacina][idGrupo][idDepartamento]) {
        qtdDose = distribuicao[idVacina][idGrupo][idDepartamento]
    }

    return qtdDose;
}

export function CampanhaNacionalGetTotalDistribuicaoVacinaGrupo(idVacina, idGrupo) {
    const distribuicao = $store.getters["campanhaNacionalState/getDistribuicao"];
    let qtdDose = 0;
    if (distribuicao[idVacina] && distribuicao[idVacina][idGrupo]) {
        const dados = distribuicao[idVacina][idGrupo];
        qtdDose = Object.values(dados).reduce((total, item) => total + item.qtd_dose, 0);
    }
    return qtdDose;
}


// setters vuex 
export function CampanhaNacionalSetInit(payload) {
    $store.dispatch("campanhaNacionalState/setInit", payload);
}
export function CampanhaNacionalSetDescricao(descricao) {
    $store.dispatch("campanhaNacionalState/setDescricao", descricao);
}
export function CampanhaNacionalSetDoencaSelecionada(doenca) {
    $store.dispatch("campanhaNacionalState/setDoencaSelecionada", doenca);
}
export function CampanhaNacionalSetVacinasSelecionada(vacinas) {
    $store.dispatch("campanhaNacionalState/setVacinasSelecionada", vacinas);
}
export function CampanhaNacionalSetQtdAdesoes(qtd) {
    $store.dispatch("campanhaNacionalState/setQtdAdesoes", qtd);
}
export function CampanhaNacionalResetVacinas() {
    $store.dispatch("campanhaNacionalState/resetVacinas");
}
export function CampanhaNacionalSetDataInicioSelecionado(dataInicio) {
    $store.dispatch("campanhaNacionalState/setDataInicioSelecionado", dataInicio);
}
export function CampanhaNacionalSetDataTerminoSelecionado(dataTermino) {
    $store.dispatch("campanhaNacionalState/setDataTerminoSelecionado", dataTermino);
}
export function CampanhaNacionalSetGruposSelecionadoPorVacina(vacina, grupos) {
    $store.dispatch("campanhaNacionalState/setGruposSelecionadoPorVacina", { vacina: vacina, grupos: grupos });
}
export function CampanhaNacionalDeleteGrupoSelecionado(vacina, grupo) {
    $store.dispatch("campanhaNacionalState/deleteGrupoSelecionado", { vacina: vacina, grupo: grupo });
}
export function CampanhaNacionalSetPrecificacaoVacinaGrupoEmpresaValor(idVacina, idGrupo, valor) {
    $store.dispatch("campanhaNacionalState/setPrecificacaoVacinaGrupoEmpresa", {
        ...idVacina,
        ...idGrupo,
        ...valor
    });
}
export function CampanhaNacionalSetPrecificacaoDoseGestoTaxaVacinaGrupoOperadorValores(idVacina, idGrupo, idDepartamento, campoAlteracao, valor) {
    $store.dispatch("campanhaNacionalState/setPrecificacaoVacinaGrupoOperador", {
        idVacina: idVacina,
        idGrupo: idGrupo,
        idDepartamento: idDepartamento,
        campoAlteracao: campoAlteracao,
        valor: valor
    });
}
export function CampanhaNacionalSetQtdDistribuicaoVacinaGrupoOperador(idVacina, idGrupo, idDepartamento, qtd) {
    $store.dispatch("campanhaNacionalState/setQtdDistribuicaoVacinaGrupoOperador", {
        idVacina: idVacina,
        idGrupo: idGrupo,
        idDepartamento: idDepartamento,
        qtd: qtd
    });
}
export function CampanhaNacionalDeleteDocumentoVacinaGrupo(idVacina, idGrupo, idGrupoDocumento) {
    $store.dispatch("campanhaNacionalState/deleteDocumentoVacinaGrupo", {
        idVacina: idVacina,
        idGrupo: idGrupo,
        idGrupoDocumento: idGrupoDocumento,
    });
}

export async function CampanhaNacionalSetDocumentoVacinaGrupo(idVacina, idGrupo, arquivo) {
    let formData = new FormData();
    formData.append('id_grupo', idGrupo);
    formData.append('arquivo', arquivo);

    await $http
        .post($api.saveGrupoDocumento(), formData, headers())
        .then(({ data }) => {
            $store.dispatch("campanhaNacionalState/setDocumentoVacinaGrupo", {
                idVacina: idVacina,
                idGrupo: idGrupo,
                arquivo: data.data
            });
        })
        .catch((error) => {
            console.log('CampanhaNacionalSetDocumentoVacinaGrupo', error)
        });
}
export function CampanhaNacionalSetResponse(response) {
    $store.dispatch("campanhaNacionalState/setResponse", response);
}
export function CampanhaNacionalSetListaDocumentoVacinaGrupo(idVacina, idGrupo, arquivo) {
    $store.dispatch("campanhaNacionalState/setDocumentoVacinaGrupo", {
        idVacina: idVacina,
        idGrupo: idGrupo,
        arquivo: arquivo
    });
}

export function CampanhaNacionalAtualizaPrecificacaoEmModoEdicao(response) {
    $store.dispatch("campanhaNacionalState/atualizaPrecificacaoEmModoEdicao", response.campanha_vacina);
}

// getters api
export async function CampanhaNacionalSetFormDoencas() {
    await $http.get($api.saveAndGetDoenca(), { params: { ativo: true } })
        .then(({ data }) => {
            $store.dispatch("campanhaNacionalState/setFormDoencas", data);
        });
}

export async function CampanhaNacionalSetFormVacinas(idDoenca) {
    await $http.get($api.getVaccinesOfDisease(idDoenca))
        .then(({ data }) => {
            $store.dispatch("campanhaNacionalState/setFormVacinas", data);
        });
}

export async function CampanhaNacionalSetFormGrupos() {
    const params = {
        params: {
            todos: true,
            colunaOrdenar: 'descricao',
            ordem: 'asc',
        },
    }
    await $http.get($api.getGruposCampanhaNacional(), params)
        .then(({ data }) => {
            $store.dispatch("campanhaNacionalState/setFormGrupos", data);
        });
}
export async function CampanhaNacionalSalvarCampanha() {
    const payload = $store.getters["campanhaNacionalState/payload"];
    try {
        const response = await $http.post($api.campanhaNacional(), payload);

        CampanhaNacionalSetResponse({
            status: 201,
            message: ['Sua campanha nacional foi salva com sucesso.'],
            idCampanha: response.data.campanha.id_campanha
        })
        return response.data;
    } catch ({ response }) {
        let message = [];

        if (response.data.errors instanceof Object) {
            Object.entries(response.data.errors).forEach(([key, value]) => {
                value.forEach(item => {
                    message.push(item);
                });
            });
        } else {
            message.push(response.data.errors);
        }

        CampanhaNacionalSetResponse({
            status: response.status,
            message: message
        })
    }
}

export async function CampanhaNacionalEditarCampanha(idCampanha) {
    const payload = $store.getters["campanhaNacionalState/payload"];
    try {
        const response = await $http.put($api.campanhaNacionalId(idCampanha), payload);

        CampanhaNacionalSetResponse({
            status: 201,
            message: ['Sua campanha nacional foi salva com sucesso.'],
            idCampanha: idCampanha
        })
        return response.data;
    } catch ({ response }) {
        let message = [];

        if (response.data.errors instanceof Object) {
            Object.entries(response.data.errors).forEach(([key, value]) => {
                value.forEach(item => {
                    message.push(item);
                });
            });
        } else {
            message.push(response.data.errors);
        }

        CampanhaNacionalSetResponse({
            status: response.status,
            message: message
        })
    }
}