<template>
  <div class="d-flex justify-content-between w-100">
    <h4 class="text-custom-blue mb-0">{{ grupo.descricao }}</h4>
    <b-badge class="rounded-pill" :variant="badge.variant">
      {{ badge.label }}
    </b-badge>
  </div>
</template>

<script>
import { BBadge } from "bootstrap-vue";
import { CampanhaNacionalGetPrecificacaoStatus } from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";
export default {
  components: {
    BBadge,
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
    grupo: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      completo: {
        variant: "light-success",
        label: "Completo",
      },
      incompleto: {
        variant: "light-danger",
        label: "Incompleto",
      },
      badge: {},
    };
  },
  mounted() {
    this.setBadge();
  },
  methods: {
    estaCompleto() {
      return CampanhaNacionalGetPrecificacaoStatus();
    },
    setBadge() {
      let estaCompletoStatus = this.estaCompleto()[this.vacina.id_vacina][this.grupo.id_grupo];
      this.badge = estaCompletoStatus ? this.completo : this.incompleto;
    },
  },
  watch: {
    "$store.state.campanhaNacionalState.watchers.precificacao": {
      immediate: true,
      handler() {
        this.setBadge();
      },
    },
  },
};
</script>
